<template>
    <div class="top">
        <div class="t-header">
            <div class="title">
                자주 문의하시는 내용을 안내해 드립니다.<br>
                <span>FAQ</span>
            </div>
        </div>
        <div class="filter-wrap">
            <div class="search">
                <el-select value="제목" interface="popover" ok-text="확인" cancel-text="취소" class="select center"
                           v-model="filterInputOption"
                           @change="parentSetData('filterInputOption', filterInputOption)">
                    <el-option :value="`su`" :label="`제목`"></el-option>
                    <el-option :value="`co`" :label="`내용`"></el-option>
                </el-select>
                <div class="input">
                    <input type="text" placeholder="검색.."
                           :value="filterInput"
                           @input="parentSetData('filterInput', $event.target.value)"
                           @keypress.enter="setFilterFunc(filterInputOption, filterInput)"></input>
                    <button class="search_btn" @click="setFilterFunc(filterInputOption, filterInput)"></button>
                </div>
            </div>
            <div class="category-wrap">
                <button class="category-btn" :class="{'active' : filterCate === ''}"
                        @click="setFilterFunc('cid', '')">전체
                </button>
                <button class="category-btn"
                        v-for="cate in categoryList"
                        :key="`cate${cate.fc_idx}`"
                        :class="{'active' : filterCate === cate.fc_idx}"
                        @click="setFilterFunc('cid', cate.fc_idx)">{{cate.fc_category}}
                </button>
            </div>


        </div>
    </div>
</template>

<script>
    import boardFilterUtil from "@/components/board/boardFilterUtil";

    const {filterConfig} = boardFilterUtil;
    export default {
        name: "BoardFilterFaqLayout",
        inject: ['setPage', 'setData', 'setFilter'],
        props: ['filterData'],
        data() {
            return {
                categoryList: [],
                filterCate: '',
                filterInput: '',
                filterInputOption: 'su',
            }
        },
        computed: {},
        created() {
            this.getCategory();
        },
        mounted() {
            this.setterFilterDataInData();

        },
        methods: {
            setterFilterDataInData() {
                let classnames = Object.keys(this.filterData);
                classnames.forEach(classname => {
                    let obj = this.filterData[classname];
                    let type = filterConfig[obj.key].type;
                    if (this.hasOwnProperty(classname)) {
                        // this[classname] =
                        if (type == 'query') {
                            this[classname] = obj.query;
                        } else if (type === 'between') {
                            this[classname] = [obj.start, obj.end];
                        } else if (type === 'match') {
                            if (isNaN(obj.value)) {
                                this[classname] = obj.value;
                            } else {
                                this[classname] = Number(obj.value);
                            }

                        }
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }

                    }
                })
            },
            getCategory() {
                try {
                    this.$api.$board.getFaqCategory({ip: 0}).then(res => res.Data.Result).then(res => {
                        if (res.Info.type === 1) {
                            this.categoryList = res.List;
                        }
                    })
                } catch (e) {
                    console.log(e)
                }
            },
            parentSetData(key, value) {
                this[key] = value;
                this.setData(key, value);
            },
            setFilterFunc(key, value) {
                let classname = filterConfig[key].classname;
                if (!classname) {
                    return;
                }
                this[classname] = value;
                this.setData(classname, value);
                this.setFilter(key, value);
            },
        },
        watch: {},
    }
</script>

<style scoped>

</style>